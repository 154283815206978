import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { indexDmi1, indexDmi2, stockDmi1, stockDmi2, } from '~/pages/ok1788_stock/_private/indicatorsParameter';
//寶塔指標變形
export const ok1788_customized2 = createIndicator({
    displayName: 'customized2',
    id: 'ok1788customized2',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            // const val = this.ok1788customized2(this._input(1), this._input(0))
            // const result = this._context.new_var()
            // if (isNaN(val[1])) {
            //   result.set(val[0])
            // } else if (isNaN(val[0])) {
            //   result.set(val[1])
            // }
            // return [result.get(0)]
            const interval = context.symbol.period;
            const symbolNumber = context.symbol.ticker;
            const isStockSymbol = symbolNumber.match(/^[\d]{4}$/);
            const customized2DMI1 = isStockSymbol ? stockDmi1(interval) : indexDmi1(interval);
            const customized2DMI2 = isStockSymbol ? stockDmi2(interval) : indexDmi2(interval);
            const dmi = this.dmi(customized2DMI1, customized2DMI1);
            const di_long = dmi[0];
            const di_short = dmi[1];
            const adx = dmi[2];
            const di_long_array = this._context.new_var(di_long);
            const di_short_array = this._context.new_var(di_short);
            const long = this.PineJS.Std.sma(di_long_array, customized2DMI2, this._context);
            const short = this.PineJS.Std.sma(di_short_array, customized2DMI2, this._context);
            const result = this._context.new_var();
            if (long > short) {
                result.set(long - short);
            }
            if (long < short) {
                result.set(long - short);
            }
            return [result.get(0)];
        },
    },
    metainfo: {
        is_price_study: !1,
        _metainfoVersion: 42,
        isTVScript: !1,
        isTVScriptStub: !1,
        is_hidden_study: !1,
        plots: [{ id: 'plot_0', type: 'line' }],
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 5,
                    trackPrice: !1,
                    transparency: 20,
                    visible: !0,
                    color: '#f2c34f',
                },
            },
            precision: 2,
            // inputs: { in_0: 14, in_1: 5 },
        },
        styles: {
            plot_0: {
                title: '柱狀圖',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        inputs: [
        // {
        //   id: 'in_0',
        //   name: 'DMI-length',
        //   defval: 14,
        //   type: 'integer',
        //   min: 1,
        //   max: 240,
        // },
        // {
        //   id: 'in_1',
        //   name: '趨勢平滑',
        //   defval: 5,
        //   type: 'integer',
        //   min: 1,
        //   max: 120,
        // },
        ],
    },
});
