import { css } from '@emotion/react';
import { memo } from 'react';
import { proxy, useSnapshot } from 'valtio';
import { fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import Styleds from './styleds';
import { FuiTabs2 } from '~/pages/heineken_template/components/FuiTabs2';
import { EntrySymbolList } from './component/EntrySymbolList';
import { HoldingSymbolList } from './component/HoldingSymbolList';
import { FirebaseSymbolList } from './component/FirebaseSymbolList';
import { RankSymbolList } from './component/RankSymbolList';
import AboveAvgLine from '~/modules/investment-consultant/aboveAvgLine/StockAboveMaChart';
export const ok1788Store1 = proxy({
    stockType: 'tw50',
    tabs: new FuiTabs2(['entry', 'holding', 'firebase', 'ranking']),
    virtualDate: 5,
    prarameterMACD: 0,
    prarameterDMI1: 0,
    prarameterDMI2: 0,
    prarameterKD1: 0,
    prarameterKD2: 0,
    prarameterKD3: 0,
    longLine1: 0,
    longLine2: 0,
    shortLine1: 0,
    shortLine2: 0,
});
//const indicators = [ok1788_customized0, ok1788_customized1, ok1788_customized2, ok1788_customized3]
export const OK1788Stock_SidePane1 = memo(function OK1788_SidePane1(props) {
    const state = useSnapshot(ok1788Store1);
    return (<Styleds.SideBar>
      MACD({state.prarameterMACD}) DMI1({state.prarameterDMI1},{state.prarameterDMI2}) KD (
      {state.prarameterKD1},{state.prarameterKD2},{state.prarameterKD3})
      <Styleds.MainSidebarTilte>股市易點靈</Styleds.MainSidebarTilte>
      <Styleds.SubTitle>市場熱度</Styleds.SubTitle>
      <Styleds.ChartContent>
        <AboveAvgLine.display market1='上市櫃' market2='上市櫃' ma1={20} ma2={60} days={60}/>
      </Styleds.ChartContent>
      <Styleds.ButtonGroup>
        <ok1788Store1.tabs.WithButton to='entry'>
          <Styleds.ScreenerButton onClick={() => {
            ok1788Store1.virtualDate = 5;
        }} active={state.tabs.store.active === 'entry'}>
            近期訊號
          </Styleds.ScreenerButton>
        </ok1788Store1.tabs.WithButton>

        <ok1788Store1.tabs.WithButton to='holding'>
          <Styleds.ScreenerButton onClick={() => {
            ok1788Store1.virtualDate = 60;
        }} active={state.tabs.store.active === 'holding'}>
            績效
          </Styleds.ScreenerButton>
        </ok1788Store1.tabs.WithButton>

        <ok1788Store1.tabs.WithButton to='firebase'>
          <Styleds.ScreenerButton active={state.tabs.store.active === 'firebase'}>
            精選
          </Styleds.ScreenerButton>
        </ok1788Store1.tabs.WithButton>

        <ok1788Store1.tabs.WithButton to='ranking'>
          <Styleds.ScreenerButton active={state.tabs.store.active === 'ranking'}>
            權值50
          </Styleds.ScreenerButton>
        </ok1788Store1.tabs.WithButton>
      </Styleds.ButtonGroup>
      <div css={css `
          ${fill_vertical_cross_center};
          height: calc(100% - 320px);
        `}>
        <ok1788Store1.tabs.WithContent for='entry'>
          <EntrySymbolList />
        </ok1788Store1.tabs.WithContent>
        <ok1788Store1.tabs.WithContent for='holding'>
          <HoldingSymbolList />
        </ok1788Store1.tabs.WithContent>
        <ok1788Store1.tabs.WithContent for='firebase'>
          <FirebaseSymbolList />
        </ok1788Store1.tabs.WithContent>
        <ok1788Store1.tabs.WithContent for='ranking'>
          <RankSymbolList />
        </ok1788Store1.tabs.WithContent>
      </div>
    </Styleds.SideBar>);
});
