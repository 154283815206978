import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { indexKd1, indexKd2, indexKd3, stockKd1, stockKd2, stockKd3, } from '~/pages/ok1788_stock/_private/indicatorsParameter';
//籌碼變形
/** Return: K, D */
export const ok1788_customized3 = createIndicator({
    id: 'ok1788customized3',
    displayName: 'customized3',
    constructorScope: {
        init(context, inputCallback) {
            // const ticker = this.PineJS.Std.ticker(this._context)
            //   .toString()
            //   .replace(/[^\w-]/g, '')
            // const symbol = ticker + '#big3_net_stock'
            // this._context.new_sym(symbol, this.PineJS.Std.period(this._context))
        },
        main(context, inputCallback) {
            // this._context.select_sym(1)
            const interval = context.symbol.period;
            const symbolNumber = context.symbol.ticker;
            const isStockSymbol = symbolNumber.match(/^[\d]{4}$/);
            const customized3KD1 = isStockSymbol ? stockKd1(interval) : indexKd1(interval);
            const customized3KD2 = isStockSymbol ? stockKd2(interval) : indexKd2(interval);
            const customized3KD3 = isStockSymbol ? stockKd3(interval) : indexKd3(interval);
            const val = this.ok1788customized3(customized3KD1, customized3KD2, customized3KD3);
            return [val[0]];
        },
    },
    metainfo: {
        _metainfoVersion: 40,
        scriptIdPart: '',
        is_hidden_study: false,
        is_price_study: false,
        isCustomIndicator: true,
        plots: [{ id: 'plot_0', type: 'line' }],
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 5,
                    trackPrice: false,
                    transparency: 20,
                    color: '#4895ed',
                },
            },
            precision: 0,
            // inputs: { in_0: 9, in_1: 3, in_2: 3 },
        },
        styles: {
            plot_0: {
                title: 'value',
                histogramBase: 0,
            },
        },
        inputs: [
        // {
        //   id: 'in_0',
        //   name: 'rsv',
        //   defval: 9,
        //   type: 'integer',
        //   min: 1,
        //   max: 240,
        // },
        // {
        //   id: 'in_1',
        //   name: 'k%',
        //   defval: 3,
        //   type: 'integer',
        //   min: 1,
        //   max: 240,
        // },
        // {
        //   id: 'in_2',
        //   name: 'd%',
        //   defval: 3,
        //   type: 'integer',
        //   min: 1,
        //   max: 240,
        // },
        ],
    },
});
