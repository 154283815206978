import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const stockDayTradeAvgPrice = createIndicator({
    displayName: '主力成本',
    id: 'stockDayTradeAvgPrice',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const time = this.PineJS.Std.time(this._context);
            const time_hours = new Date(time).getHours();
            const time_minutes = new Date(time).getMinutes();
            const close = this.PineJS.Std.hlc3(this._context);
            const ticks = this.PineJS.Std.volume(this._context);
            const total_closeticks = this._context.new_var();
            const total_ticks = this._context.new_var();
            const avg_price = total_closeticks / total_ticks;
            if (time_hours === 9 && time_minutes === 0) {
                total_closeticks.set(close * ticks);
                total_ticks.set(ticks);
            }
            else {
                total_closeticks.set(total_closeticks + close * ticks);
                total_ticks.set(total_ticks + ticks);
            }
            return [avg_price];
        },
    },
    metainfo: {
        //宣告
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
        ],
        defaults: {
            //樣式調整
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 10,
                    visible: !0,
                    color: '#5069ad',
                },
            },
            //填充區域
        },
        styles: {
            plot_0: {
                title: '均價線',
                histogramBase: 0,
            },
        },
        is_price_study: !0,
        inputs: [],
        scriptIdPart: '',
    },
});
