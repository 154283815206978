import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { indexMacd, indexDmi1, indexDmi2, indexKd1, indexKd2, indexKd3, stockMacd, stockDmi2, stockDmi1, stockKd1, stockKd2, stockKd3, } from '~/pages/ok1788_stock/_private/indicatorsParameter';
import { ok1788Store1 } from '~/pages/ok1788_stock/_private/ok1788Stock_SidePane1';
/** 多空分界 */
export const ok1788_customized0 = createIndicator({
    displayName: 'customized0',
    id: 'ok1788customized0',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const itime = this.PineJS.Std.time(this._context);
            const volume = this.PineJS.Std.volume(this._context);
            const interval = context.symbol.period;
            const symbolNumber = context.symbol.ticker;
            const isStockSymbol = symbolNumber.match(/^[\d]{4}$/);
            const customized1MACD = isStockSymbol ? stockMacd(interval) : indexMacd(interval);
            const customized2DMILength = isStockSymbol ? stockDmi1(interval) : indexDmi1(interval);
            const customized2DMIAvg = isStockSymbol ? stockDmi2(interval) : indexDmi2(interval);
            const customized3Length1 = isStockSymbol ? stockKd1(interval) : indexKd1(interval);
            const customized3Length2 = isStockSymbol ? stockKd2(interval) : indexKd2(interval);
            const customized3Length3 = isStockSymbol ? stockKd3(interval) : indexKd3(interval);
            const customizedSignalMode = this._input(0);
            ok1788Store1.prarameterMACD = customized1MACD;
            ok1788Store1.prarameterDMI1 = customized2DMILength;
            ok1788Store1.prarameterDMI2 = customized2DMIAvg;
            ok1788Store1.prarameterKD1 = customized3Length1;
            ok1788Store1.prarameterKD2 = customized3Length2;
            ok1788Store1.prarameterKD3 = customized3Length3;
            //
            const macd = this.ok1788customized1(customized1MACD);
            const di_long = this.dmi(customized2DMILength, customized2DMILength)[0];
            const di_long_array = this._context.new_var(di_long);
            const dmi_UP = this.PineJS.Std.sma(di_long_array, customized2DMIAvg, this._context);
            const K = this.ok1788customized3(customized3Length1, customized3Length2, customized3Length3)[0];
            const di_short = this.dmi(customized2DMILength, customized2DMILength)[1];
            const di_short_array = this._context.new_var(di_short);
            const dmi_DN = this.PineJS.Std.sma(di_short_array, customized2DMIAvg, this._context);
            //
            const strong_long = macd >= 0 && dmi_UP - dmi_DN >= 0 && K >= 0;
            const weak_long = (macd >= 0 && dmi_UP - dmi_DN >= 0 && K < 0) ||
                (macd >= 0 && dmi_UP - dmi_DN < 0 && K >= 0) ||
                (macd < 0 && dmi_UP - dmi_DN >= 0 && K >= 0);
            const weak_short = (macd < 0 && dmi_UP - dmi_DN < 0 && K >= 0) ||
                (macd < 0 && dmi_UP - dmi_DN >= 0 && K < 0) ||
                (macd >= 0 && dmi_UP - dmi_DN < 0 && K < 0);
            const strong_short = macd < 0 && dmi_UP - dmi_DN < 0 && K < 0;
            const colorIndex = () => {
                if (strong_long)
                    return 0;
                else if (weak_long)
                    return 1;
                else if (weak_short)
                    return 2;
                else if (strong_short)
                    return 3;
                else
                    return 4;
            };
            const time = this.PineJS.Std.time(this._context);
            const time_array = this._context.new_var(time);
            const open = this.ohlc.open;
            const close_array = this.ohlc.closeArray;
            const pass = this._context.new_var();
            const passDate = this._context.new_var();
            close_array.get(10);
            time_array.get(customized1MACD);
            //這邊處理減資等事件造成極大的缺口影響交易訊號----
            const isHasPriceGap = open / close_array.get(1) > 1.2 || close_array.get(1) / open > 1.2;
            if (pass.get(0) !== 1 && isHasPriceGap) {
                //出現減資立flag 限制交易進場
                pass.set(1);
                //記下當下時間
                passDate.set(time);
            }
            //取前N天時間 > 出現缺口的時間 就解除限制
            if (pass.get(0) === 1 && time_array.get(customized1MACD) >= passDate.get(0)) {
                pass.set(0);
            }
            //-----------------------------------------
            const marketposition = this._context.new_var();
            marketposition.get(1);
            marketposition.get(2);
            let buy_icon = NaN;
            let short_icon = NaN;
            const condition_buy = strong_long && pass.get(0) !== 1;
            const condition_short = strong_short && pass.get(0) !== 1;
            // 不是三線多/空 就歸零部位
            if (customizedSignalMode === 0) {
                if ((weak_short && volume > 1000) || (weak_long && volume > 1000)) {
                    marketposition.set(0);
                }
            }
            //沒有出現三線翻空或二空一多之前 就只有一個多訊 || 沒有出現三線翻多或二多一空之前 就只有一個空訊
            if (customizedSignalMode === 1) {
                if (marketposition.get(0) === 1 && weak_short && volume > 1000) {
                    marketposition.set(0);
                }
                if (marketposition.get(0) === -1 && weak_long && volume > 1000) {
                    marketposition.set(0);
                }
            }
            //只有三線多/空才會進場
            if (customizedSignalMode === 2) {
                if (condition_buy && volume > 1000) {
                    marketposition.set(1);
                }
                if (condition_short && volume > 1000) {
                    marketposition.set(-1);
                }
            }
            if (marketposition.get(0) === 1 && marketposition.get(1) !== 1) {
                buy_icon = 1;
            }
            if (marketposition.get(0) === -1 && marketposition.get(1) !== -1) {
                short_icon = 1;
            }
            // console.log(
            //   dayAPI(itime).format('YYYY/MM/DD'),
            //   'm:',
            //   macd.toFixed(2),
            //   'di+:',
            //   di_long.toFixed(2),
            //   'di-:',
            //   di_short.toFixed(2),
            //   'k:',
            //   K.toFixed(2),
            //   'B:',
            //   strong_long && volume > 1000,
            //   'S:',
            //   strong_short && volume > 1000,
            // )
            return [colorIndex(), buy_icon, short_icon];
        },
    },
    metainfo: {
        plots: [
            {
                id: '多空顏色',
                type: 'bar_colorer',
                palette: 'palette_0',
            },
            {
                id: 'plot_0',
                type: 'chars',
            },
            {
                id: 'plot_1',
                type: 'chars',
            },
        ],
        defaults: {
            styles: {
                plot_0: {
                    color: '#dd5e56',
                    textColor: '#dd5e56',
                    transparency: 0,
                    visible: true,
                },
                plot_1: {
                    color: '#52a49a',
                    textColor: '#52a49a',
                    transparency: 0,
                    visible: true,
                },
            },
            //樣式調整
            inputs: {
                // in_0: 60,
                // in_1: 14,
                // in_2: 5,
                // in_3: 9,
                // in_4: 3,
                // in_5: 3,
                in_0: 2,
            },
        },
        styles: {
            plot_0: {
                isHidden: false,
                location: 'BelowBar',
                char: '▲',
                size: 'small',
                text: 'B',
                title: '多單參考訊號',
            },
            plot_1: {
                isHidden: false,
                location: 'AboveBar',
                char: '▼',
                size: 'small',
                text: 'S',
                title: '空單參考訊號',
            },
        },
        palettes: {
            palette_0: {
                colors: {
                    0: {
                        color: '#dd5e56',
                        width: 1,
                        style: 0,
                    },
                    1: {
                        color: '#e39e9c',
                        width: 1,
                        style: 0,
                    },
                    2: {
                        color: '#91c9c4',
                        width: 1,
                        style: 0,
                    },
                    3: {
                        color: '#52a49a',
                        width: 1,
                        style: 0,
                    },
                    4: {
                        color: '#555555',
                        width: 1,
                        style: 0,
                    },
                },
            },
        },
        is_price_study: !0,
        inputs: [
            // {
            //   id: 'in_0',
            //   name: 'customized1MACD',
            //   defval: 60,
            //   type: 'integer',
            //   min: 1,
            //   max: 240,
            // },
            // {
            //   id: 'in_1',
            //   name: 'customized2-DMI-length',
            //   defval: 14,
            //   type: 'integer',
            //   min: 1,
            //   max: 240,
            // },
            // {
            //   id: 'in_2',
            //   name: 'customized2-DMI-趨勢平滑',
            //   defval: 5,
            //   type: 'integer',
            //   min: 1,
            //   max: 120,
            // },
            // {
            //   id: 'in_3',
            //   name: 'customized3-rsv',
            //   defval: 9,
            //   type: 'integer',
            //   min: 1,
            //   max: 240,
            // },
            // {
            //   id: 'in_4',
            //   name: 'customized3-k',
            //   defval: 3,
            //   type: 'integer',
            //   min: 1,
            //   max: 240,
            // },
            // {
            //   id: 'in_5',
            //   name: 'customized3-d',
            //   defval: 3,
            //   type: 'integer',
            //   min: 1,
            //   max: 240,
            // },
            {
                id: 'in_0',
                name: '訊號模式 ',
                defval: 2,
                type: 'integer',
                min: 0,
                max: 2,
            },
        ],
        scriptIdPart: '',
    },
});
