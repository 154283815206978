import { css } from '@emotion/react';
import { memo, useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { store } from '~/pages/heineken_template/_private/store';
import SymbolCardHoldingProfitBody from '../body/SymbolCardHoldingProfitBody';
import SymbolHoldingProfitHeader from '../header/SymbolHoldingProfitHeader';
import CircularProgress from '@mui/material/CircularProgress';
import { useVirtualHoldingSort } from '../../signalrQuoteSort/useSignalrSortCondition';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2';
import { processQuoteToReadable } from '~/utils/processQuoteToReadable';
const SymbolHoldingProfitList1 = memo(function SymbolHoldingProfitList1(props) {
    const data = props.data;
    const charting = useSnapshot(store.charting);
    const [loadComplete, setLoadComplete] = useState(false);
    useEffect(() => {
        if (data.length > 0) {
            setLoadComplete(true);
        }
    }, [data]);
    return (<div css={css `
        ${fill_vertical_cross_center};
        gap: 2px;
      `}>
      <div css={css `
          width: 100%;
          height: 40px;
        `}>
        <SymbolHoldingProfitHeader.DisplayStyled1 />
      </div>
      <div css={css `
          width: 100%;
          height: calc(100% - 40px);
          ${scrollbar2Css};
          gap: 4px;
          padding: 2px;
        `}>
        {data.map(s => {
            const { symbol, qty, averagePrice, lastModifiedDatetime, marketPrice, pnl } = s;
            return (<SymbolCardHoldingProfitBody.DisplayStyled1 currentSymbol={charting.symbol} symbol={symbol} qty={qty} pnl={pnl} averagePrice={averagePrice} marketPrice={marketPrice} lastModifiedDatetime={lastModifiedDatetime} key={symbol} onClick={() => {
                    store.charting.changeSymbol(symbol);
                    //store.charting.updateFromState()
                }}/>);
        })}
        {loadComplete === false && <CircularProgress />}
        {loadComplete === true && data.length === 0 && '尚無標的'}
      </div>
    </div>);
});
const SymbolHoldingProfitList2 = memo(function SymbolHoldingProfitList2(props) {
    const data = props.data;
    const charting = useSnapshot(store.charting);
    const [loadComplete, setLoadComplete] = useState(false);
    useEffect(() => {
        if (data.length > 0) {
            setLoadComplete(true);
        }
    }, [data]);
    const symbolData = data.map(s => s.symbol);
    useEffect(() => {
        signalrStore2.addQuote(symbolData);
        console.log('call api');
        return () => {
            signalrStore2.removeQuote(symbolData);
        };
    }, [JSON.stringify(symbolData)]);
    const value = signalrHooks2.useQuotes(symbolData);
    //使用漲幅排序
    const newData = data.map(item => {
        const matchingQuote = value.find(q => q.symbol === item.symbol);
        const quoteChanges = processQuoteToReadable(matchingQuote);
        if (matchingQuote) {
            return { ...item, changePrecent: quoteChanges.closeChangePercent };
        }
        return item;
    });
    const sortData = useVirtualHoldingSort(newData);
    return (<div css={css `
        ${fill_vertical_cross_center};
        gap: 2px;
      `}>
      <div css={css `
          width: 100%;
          height: 40px;
        `}>
        <SymbolHoldingProfitHeader.DisplayStyled2 />
      </div>
      <div css={css `
          ${fill_vertical_cross_center};
          height: calc(100% - 40px);
          ${scrollbar2Css};
          gap: 4px;
          padding: 2px;
        `}>
        {sortData.map(s => {
            const { symbol, qty, averagePrice, lastModifiedDatetime, marketPrice, pnl } = s;
            return (<SymbolCardHoldingProfitBody.DisplayStyled2 currentSymbol={charting.symbol} symbol={symbol} qty={qty} pnl={pnl} averagePrice={averagePrice} marketPrice={marketPrice} lastModifiedDatetime={lastModifiedDatetime} key={symbol} onClick={() => {
                    store.charting.changeSymbol(symbol);
                    //store.charting.updateFromState()
                }}/>);
        })}
        {loadComplete === false && (<div css={fill_vertical_all_center}>
            <span>資料載入中</span>
            <CircularProgress />
          </div>)}
        {loadComplete === true && data.length === 0 && (<div css={fill_vertical_all_center}>
            <span>尚無標的</span>
          </div>)}
      </div>
    </div>);
});
export default {
    /** 商品 成交價 成本 報酬率 日期 */
    DisplayStyled1: SymbolHoldingProfitList1,
    /** 商品 成交價 漲跌幅 報酬率 日期 */
    DisplayStyled2: SymbolHoldingProfitList2,
};
